<template>
 
  <router-view />
</template>

<style>
  .van-action-sheet__header,.van-action-sheet__item,.van-action-sheet__cancel{
    background: #1C1C1E!important;
    color:#fff;
  }
  .van-action-sheet__gap{
    background: #000!important;;
  }
  .van-dialog__content,.van-dialog__cancel,.van-dialog__confirm,.van-dialog{
    background-color: #1C1C1E!important;
  
  }
  .van-dialog__cancel,.van-dialog__content,.van-dialog__message{
    color: #fff!important;
  }
</style>
