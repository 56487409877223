import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/",
    name: "Login",
    component: import(/* webpackChunkName: "about" */ "../views/login.vue"),
  },

  {
    path: "/lanuch",
    name: "lanuch",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/lanuch.vue"),
  
  },
  {
    path: "/mine",
    name: "mine",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/mine.vue"),
  
  },

  {
    path: "/withdraw",
    name: "withdraw",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/withdraw.vue"),
  
  },

  {
    path: "/faq-detail",
    name: "faq-detail",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/faq-detail.vue"),
  
  },

  {
    path: "/record",
    name: "record",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/record.vue"),
  
  },

  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login.vue"),
  
  },

  {
    path: "/service",
    name: "service",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/service.vue"),
  
  },

  
  {
    path: "/task-record",
    name: "task-record",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/task-record.vue"),
  
  },

  {
    path: "/changepassword",
    name: "changepassword",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/changepassword.vue"),
  
  },

  {
    path: "/changecashword",
    name: "changecashword",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/changecashword.vue"),
  
  },



  

  {
    path: "/faq",
    name: "faq",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/faq.vue"),
  
  },

  {
    path: "/wallet",
    name: "wallet",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/wallet.vue"),
  
  },

  {
    path: "/vip",
    name: "vip",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/vip.vue"),
  
  },



  
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
